<template>
  <div class="invoice">
    <div class="top_bar">
      <div class="top_bar_icon" @click="handleRouter">
        <van-icon name="arrow-left" color="#7e7e7e" size="16" />
      </div>
      <div>开发票</div>
    </div>
    <van-field label="公司名称：" :border="false" v-model="form.company_name" placeholder="请输入内容" />
    <van-field label="公司税号：" :border="false" v-model="form.duty_num" placeholder="请输入内容" />
    <van-field label="邮箱地址：" :border="false" v-model="form.email" placeholder="请输入内容" />
    <div v-if="radioType === '1'">
      <van-field label="注册地址：" :border="false" v-model="reg_address" placeholder="请填写单位地址" />
      <van-field label="注册电话：" :border="false" v-model="reg_mobile" placeholder="请填写单位电话" />
      <van-field label="开启银行：" :border="false" v-model="reg_bank" placeholder="请输入开户银行" />
      <van-field label="银行账号：" :border="false" v-model="reg_banknum" placeholder="请输入银行账号" />
    </div>
    <div class="location" @click="show = true" v-show="location">
      <div>地址：</div>
      <div class="text">
        <div class="k_w" ref="province"></div>
        <div>省</div>
      </div>
      <div class="text">
        <div class="k_w" ref="city"></div>
        <div>市</div>
      </div>
      <div class="text">
        <div class="k_w" ref="area"></div>
        <div>区/县</div>
      </div>
    </div>
    <van-field label=" " :border="false" v-model="address" placeholder="详细地址" v-show="location" />
    <div class="orders clearfix">
      <div class="fl">选择订单：</div>
      <div class="fl select">{{invoiceText?invoiceText:'请选择'}}</div>
      <img class="fr" src="../../assets/image/right.svg" alt @click="handleOrder" />
    </div>
    <!-- 类型 -->
    <div class="type">
      <div>发票类型：</div>
      <van-radio-group v-model="radio" direction="horizontal" @change="handleChange">
        <van-radio name="1">
          <span class="colors_text">电子发票</span>
          <template #icon="props">
            <img :src="props.checked ? checked : no_checked" />
          </template>
        </van-radio>
        <van-radio name="2">
          <span class="colors_text">纸质发票</span>
          <template #icon="props">
            <img :src="props.checked ? checked : no_checked" />
          </template>
        </van-radio>
      </van-radio-group>
    </div>
    <!-- 专票/普票 -->
    <div class="type">
      <div>专票/普票：</div>
      <van-radio-group v-model="radioType" direction="horizontal" @change="handleChangeType">
        <van-radio name="1">
          <span class="colors_text">专票</span>
          <template #icon="props">
            <img :src="props.checked ? checked : no_checked" />
          </template>
        </van-radio>
        <van-radio name="2">
          <span class="colors_text">普票</span>
          <template #icon="props">
            <img :src="props.checked ? checked : no_checked" />
          </template>
        </van-radio>
      </van-radio-group>
    </div>
    <div class="btn btn_t" @click="handleSubmit">立即申请</div>
    <!-- 地址弹出框 -->
    <van-popup v-model="show" position="bottom" :style="{ height: '50%' }">
      <van-area title="地址选择" :area-list="areaList" @confirm="handleConfirm" />
    </van-popup>
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        company_name: "",
        duty_num: "",
        email: "",
      },
      formTip: {
        company_name: "请输入公司名称！",
        duty_num: "请输入公司税号！",
        email: "请输入邮箱地址！",
      },
      address: "",
      show: false,
      radio: "2",
      radioType:"1",
      no_checked: require("../../assets/image/no_checked.svg"),
      checked: require("../../assets/image/checked.svg"),
      location: true,
      areaList: {
        province_list: {
          // 110000: "北京市",
          // 120000: "天津市"
        },
        city_list: {
          // 110100: "北京市",
        },
        county_list: {
          // 110101: "东城区",
        }
      },
      pro: "",
      city: "",
      dist: "",
      result: [],
      reg_address: "",
      reg_mobile: "",
      reg_bank: "",
      reg_banknum: "",
      invoiceText: "",
      texts:'专票'
    };
  },

  computed: {
    changeInvoice() {
      return this.$store.state.Result.invoiceNum;
    }
  },
  watch: {
    changeInvoice(val) {
      console.log(val, "val");
      this.invoiceText = val;
    }
  },
  methods: {
    Tips(text) {
      this.$notify({ type: "danger", message: text });
    },
    handleCheck() {
      let regTaxNumVal = /^[A-Z0-9]{15}$|^[A-Z0-9]{18}$|^[A-Z0-9]{20}$/; //税号
      let regEmail = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/; //邮箱
      for (let key in this.form) {
        if (this.form[key] === "") {
          this.Tips(this.formTip[key]);
          return;
        }
      }
      //格式校验
      if (!regTaxNumVal.test(this.form.duty_num)) {
        this.$notify({ type: "danger", message: "公司税号格式不正确！" });
        return false;
      }
      if (!regEmail.test(this.form.email)) {
        this.$notify({ type: "danger", message: "邮箱格式不正确！" });
        return false;
      }
      return true;
    },
    handleRouter() {
      this.$router.go(-1);
    },
    handleChange(name) {
      if (name == "1") {
        this.location = false;
      } else {
        this.location = true;
      }
    },
    handleChangeType(e){
      console.log(e,"e")
      if(e==1){
        this.texts = '专票'
      }else{
         this.texts = '普票'
      }
    },
    handleConfirm(columnsNum) {
      this.$refs.province.innerHTML = columnsNum[0].name;
      this.$refs.city.innerHTML = columnsNum[1].name;
      this.$refs.area.innerHTML = columnsNum[2].name;
      this.pro = columnsNum[0].code;
      this.city = columnsNum[1].code;
      this.dist = columnsNum[2].code;
      this.show = false;
      console.log(columnsNum, "saveInvoicesaveInvoicesaveInvoice");
    },
    handleOrder() {
      this.$router.push({
        path: "/invoiceOrder/index"
      });
    },
    arrayToObj(arr) {
      let list = {};
      for (let key in arr) {
        list[key] = arr[key];
      }
      return list;
      // return Object.assign({}, arr);
    },

    async handleSubmit() {
      console.log(this.$store.state.Result.resultOrder, ",store的内容");
      console.log(
        this.$store.state.Result.resultOrder.toString(),
        "JSON.stringify(this.$store.state.Result.resultOrder)"
      );

      if (!this.handleCheck()) return;
      const data = await this.$api.saveInvoice({
        ...this.form,
        address: this.address,
        pro: this.pro,
        city: this.city,
        dist: this.dist,
        order_id: this.$store.state.Result.resultOrder.toString(),
        type: this.radio,
        reg_address: this.reg_address,
        reg_mobile: this.reg_mobile,
        reg_bank: this.reg_bank,
        reg_banknum: this.reg_banknum,
        fp_name:this.texts
      });
      console.log(data, "提交结果");
      if (data.data.code === 1) {
        console.log(data, "提交结果");
        this.$notify({ type: "success", message: data.data.msg });

        setTimeout(() => {
          this.$router.push({
            path: "/personal/index"
          });
        }, 1000);
        this.$store.commit("Count", []);
        this.$store.commit("Num", "");
      } else {
        this.$notify({ type: "danger", message: data.data.msg });
      }
    },
    async handleprovince() {
      const data = await this.$api.province();
      if (data.data.code === 1) {
        this.areaList.province_list = data.data.data;
      } else {
        this.$notify({ type: "danger", message: data.data.msg });
      }
    },
    async handlecity() {
      const data = await this.$api.city();
      if (data.data.code === 1) {
        this.areaList.city_list = data.data.data;
      } else {
        this.$notify({ type: "danger", message: data.data.msg });
      }
    },
    async handledist() {
      const data = await this.$api.dist();
      if (data.data.code === 1) {
        this.areaList.county_list = data.data.data;
      } else {
        this.$notify({ type: "danger", message: data.data.msg });
      }
    }
  },
  mounted() {
    this.result = this.$route.query.result;
    this.invoiceText = this.$route.query.orderSn;
    console.log(this.result, "result");
    this.handleprovince();
    this.handlecity();
    this.handledist();
  },
  beforeRouteLeave(to, from, next) {
    if (to.path === "/invoiceOrder/index") {
      from.meta.keepAlive = true;
    } else {
      from.meta.keepAlive = false;
      this.$store.commit("Count", []);
      this.$store.commit("Num", "");
    }
    next();
  },
  // beforeRouteEnter(to, from, next) {
  //   next(_this => {
  //     if (from.path !== "/invoiceOrder/index") {
  //       _this.$store.commit("Count", []);
  //       _this.$store.commit("Num", "");
  //     }
  //   });
  // }
};
</script>
<style lang="scss" scoped>
.invoice {
  width: 375px;
  height: auto;
  padding-top: 40px;
  padding-bottom: 40px;
  box-sizing: border-box;
  .location {
    width: 351px;
    height: 45px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    font-size: 15px;
    color: rgba(51, 51, 51, 1);
    line-height: 15px;
    border-bottom: 1px solid #ebebeb;
    .text {
      display: flex;
      width: 85px;
      text-align: right;
      .k_w {
        width: 70px;
        overflow: hidden;
        padding: 0 4px;
        box-sizing: border-box;
      }
      &:last-child {
        width: 116px;
      }
    }
  }
  .orders {
    width: 351px;
    height: 45px;
    line-height: 45px;
    font-size: 15px;
    color: rgba(51, 51, 51, 1);
    border-bottom: 1px solid #ebebeb;
    margin: 0 auto;
    img {
      width: 11px;
      height: 11px;
      margin-top: 17px;
    }
    .select {
      color: rgba(153, 153, 153, 1);
      margin-left: 16px;
      width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .type {
    width: 351px;
    height: 45px;
    font-size: 15px;
    color: rgba(51, 51, 51, 1);
    border-bottom: 1px solid #ebebeb;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .colors_text {
      color: rgba(153, 153, 153, 1);
    }
    img {
      width: 12px;
      height: 12px;
      margin-top: -3px;
      margin-left: 35px;
    }
  }
  .btn_t {
    margin-top: 40px;
  }
}
</style>
